import { EndpointsActions } from './actions';

const initialState = {
	API_BASE: '',
	SHARE_BASE: '',
	PUBLISH_APP_BASE: '',
	FAN_APP_BASE: '',
	CANONICAL_URL: ''
};

export default function endpointsReducer(state = initialState, action: EndpointsActions) {
	switch (action.type) {
		case 'UPDATE_ENDPOINTS_URLS':
			return {
				API_BASE: action.payload.API_BASE,
				SHARE_BASE: action.payload.SHARE_BASE,
				PUBLISH_APP_BASE: action.payload.PUBLISH_APP_BASE,
				FAN_APP_BASE: action.payload.FAN_APP_BASE,
				CANONICAL_URL: action.payload.FAN_APP_BASE.replace('the.', 'watch.').replace('.app', '.tv')
			};
		default:
			return state;
	}
}
