import { lazy, StrictMode, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/browser';
import { BLACKLISTED_SENTRY_URLS, IGNORED_SENTRY_ERRORS } from './constants/IgnoredSentryErrors';
import { getAppConfig } from '../config/appConfig';
import initDayJs from './utils/initDayJs';
import { initFlagsmith } from './utils/featureFlags/flagSmith';
import { FeatureFlagsProvider } from './components/common/FeatureFlagsProvider/FeatureFlagsProvider';
import Spinner from './components/common/Spinner/Spinner';
import RoutePaths from './navigation/routePaths';
import { captureException } from '@sentry/react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import Pseudo from 'i18next-pseudo';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import './static/sass/App.scss';

const AppLoader = lazy(() => import('./AppLoader'));
const PopUp = lazy(() => import('./feature/pop-up/PopUp'));

// eslint-disable-next-line
require('intersection-observer');

initDayJs();

const initSentry = () => {
	const appConfig = getAppConfig();
	if (appConfig.SENTRY_DSN) {
		try {
			Sentry.init({
				dsn: appConfig.SENTRY_DSN,
				normalizeDepth: 10,
				release: `${appConfig.IMAGE_NAME}@${appConfig.RELEASE_TAG}`,
				integrations: [
					new DedupeIntegration(),
					new BrowserTracing({
						routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
					})
				],
				transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
				tracesSampleRate: 1.0,
				ignoreErrors: IGNORED_SENTRY_ERRORS,
				denyUrls: BLACKLISTED_SENTRY_URLS,
				environment: appConfig.ENV_FULL_NAME,
				debug: false
			});
		} catch (e) {
			console.error('Sentry init failed', e);
		}
	}
};

i18n.use(Backend)
	.use(new Pseudo({ enabled: getAppConfig().ENABLE_PSEUDO }))
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		backend: {
			loadPath: '/locales/{{lng}}.json'
		},
		postProcess: ['pseudo'],
		react: {
			useSuspense: false
		},
		returnNull: false
	})
	.catch(e => captureException(e));

const Root = () => {
	useEffect(() => {
		initSentry();
		initFlagsmith().catch(e => captureException(e));
	}, []);

	return (
		<StrictMode>
			<Suspense fallback={<Spinner className="spinner--center" />}>
				<FeatureFlagsProvider>
					<BrowserRouter>
						<Switch>
							<Route path={RoutePaths.PopUp}>
								<PopUp />
							</Route>
							<Route path="*">
								<AppLoader />
							</Route>
						</Switch>
					</BrowserRouter>
				</FeatureFlagsProvider>
			</Suspense>
		</StrictMode>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
