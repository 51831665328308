import { ConfigFromEnvVariables } from '../appConfig';

const devConfig: ConfigFromEnvVariables = {
	ENV_FULL_NAME: 'development',
	IMAGE_TAG: '',
	FALLBACK_API_BASE: 'https://watch.tst.recast.tv',
	FALLBACK_SHARE_BASE: 'https://share-tst.recast.tv',
	FALLBACK_PUBLISH_BASE: 'https://publish.tst.recast.tv',
	FACEBOOK_APP_ID: '887971718675262',
	DRM_LICENCE_ENDPOINT_KEY: 'ERoLyHtz5ls4yoknpHmeffnAmXX3eOEO',
	ENDPOINTS_URL: 'https://watch.tst.recast.tv/api/endpoints',
	FALLBACK_APP_URL: 'http://recast.app.local',
	STRIPE_PUBLISHABLE_KEY:
		'pk_test_51KPQzpD9hyX5XdRnMcaan8mwi3ldWrnO45BhjupJf0k2pzz3SGVI0tCrxgBSMvCtrBs4zjQcNSkDgwtLdRbGxhwt00ZSOmuY0n',
	GOOGLE_ANALYTICS_MEASUREMENT_ID: '',
	GOOGLE_RECAPTCHA_SITE_KEY: '6LcZ5sUhAAAAAE6cBbEq6LpTo-vIW5ApoNRbqVFj',
	CHROME_CAST_RECEIVER_ID: 'A56099EF',
	SENTRY_DSN: '',
	AB_TEST_CAROUSEL_ID: 'jAqnw4RSQEiIUyeYXH4iVQ',
	FLAGSMITH_ENVIRONMENT_ID: 'BPQEXJqsijYV2FgxjTjWiJ',
	FLAGSMITH_POLLING_INTERVAL_MS: 300000,
	STREAM_CHAT_KEY: 'w2gcgwzv9epe',
	VAST_URL: 'https://tv.springserve.com/rt/19765',
	USERPILOT_TOKEN: 'NX-b90347f3',
	ENABLE_PSEUDO: true,
	BRAZE_API_KEY: '1bf51786-eb8a-40ba-a08c-c75a0e243241',
	BRAZE_BASE_URL: 'sdk.fra-02.braze.eu',
	MIXPANEL_TOKEN: ''
};

export default devConfig;
