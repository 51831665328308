/* eslint-disable @typescript-eslint/no-floating-promises */
import flagsmith from 'flagsmith';
import { getAppConfig } from '../../../config/appConfig';
import { getTokenPayload } from '../authApi/jwt';
import { FEATURE_FLAGS, FEATURE_FLAG_VALUES } from './featureFlags';
import { captureException } from '@sentry/react';

export const initFlagsmith = () =>
	new Promise<void>((resolve, reject) => {
		const token = getTokenPayload();

		const featureFlags = Object.keys(FEATURE_FLAGS).reduce((acc, key) => {
			return {
				...acc,
				[FEATURE_FLAGS[key as keyof typeof FEATURE_FLAGS]]: { enabled: false }
			};
		}, {});

		const valueFlags = Object.keys(FEATURE_FLAGS).reduce((acc, key) => {
			return {
				...acc,
				[FEATURE_FLAG_VALUES[key as keyof typeof FEATURE_FLAG_VALUES]]: { enabled: false }
			};
		}, {});

		const defaultFlags = { ...featureFlags, ...valueFlags };

		flagsmith.init({
			environmentID: getAppConfig().FLAGSMITH_ENVIRONMENT_ID,
			identity: token?.uid || undefined,
			traits: {
				isAdmin: !!token?.isAdmin,
				isContributor: !!token?.isContributor,
				isPartnerStaff: !!token?.isPartnerStaff,
				isPublisher: !!token?.isPublisher
			},
			cacheFlags: true,
			api: 'https://watch.recast.tv/flagsmith/api/v1/',
			onChange: () => {
				resolve();
			},
			onError: error => {
				console.error(error);
				captureException(error);
				reject(error);
			},
			defaultFlags: {
				...defaultFlags,
				[FEATURE_FLAGS.SIGNUP_PROVIDERS]: { enabled: true, value: '["google","facebook"]' }
			}
		});

		flagsmith.startListening(getAppConfig().FLAGSMITH_POLLING_INTERVAL_MS);
	});
