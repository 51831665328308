import { ReactElement } from 'react';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';

interface FeatureFlagsProviderProps {
	children: ReactElement;
}

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => (
	<FlagsmithProvider flagsmith={flagsmith}>{children}</FlagsmithProvider>
);
