import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { refreshAndAddToken, ApiResponse } from '../api/api';
import { SocialSignupType, getAuthApiUrl } from './auth';
import { RecaptchaData } from '../../hooks/useRecaptcha';
import { stopIdentifyingUserForFlags } from '../featureFlags/featureFlags';
import { AUTH_TOKENS_KEY } from '../../constants/Common';

const CONTACT_URL_SUFFIX = 'contact';
const USER_URL_SUFFIX = 'user';
const SOCIAL_URL_SUFFIX = 'social/contact';

export interface GetUserContactMethodsResponse extends ApiResponse {
	email: string;
	phone: string;
}

const authClient = axios.create();
const addTokenIfRequired = (config: InternalAxiosRequestConfig) => {
	return refreshAndAddToken(config);
};
authClient.interceptors.request.use(addTokenIfRequired, Promise.reject);

export const updatePassword = async (password: string) => {
	return await authClient.put(getAuthApiUrl(USER_URL_SUFFIX), { password });
};

export const logoutAllDevices = async () => {
	return await authClient.delete(getAuthApiUrl('logoutAllDevices')).finally(async () => {
		localStorage.removeItem(AUTH_TOKENS_KEY);
		await stopIdentifyingUserForFlags();
	});
};

export const updatePhoneNumber = async (phone: string, recaptchaData?: RecaptchaData) => {
	return await authClient.post(getAuthApiUrl(CONTACT_URL_SUFFIX), {
		phone,
		...(!!recaptchaData && { recaptchaData })
	});
};

export const verifyPhoneNumberUpdate = async (phone: string, verificationCode: string) => {
	return await authClient.put(getAuthApiUrl(CONTACT_URL_SUFFIX), { phone, verificationCode });
};

export const getUserContactMethods = async () => {
	const res = await authClient.get<AxiosResponse<GetUserContactMethodsResponse>>(
		getAuthApiUrl(`${USER_URL_SUFFIX}/contact`)
	);
	return res.data.data;
};

export const deleteSocialConnection = (type: SocialSignupType) => {
	return authClient.delete(getAuthApiUrl(`${SOCIAL_URL_SUFFIX}/${type}`));
};

export const deleteUser = async () => {
	return authClient.delete(getAuthApiUrl(USER_URL_SUFFIX));
};
