import appReducer from './app/reducer';
import authOldReducer from './auth/reducer';
import contentReducer from './content/reducer';
import meReducer from './me/reducer';
import notificationReducer from './notification/reducer';
import playerReducer from './player/reducer';
import paymentReducer from './payment/reducer';
import endpointsReducer from './endpoints/reducer';
import {
	combineReducers,
	configureStore,
	Action,
	ThunkDispatch,
	ThunkAction,
	createListenerMiddleware
} from '@reduxjs/toolkit';
import { isDevelopment } from '../domain/app/appManager';
import {
	signupSlice,
	signupApiErrorMiddleware,
	topUpSlice,
	purchaseSlice,
	purchaseStorage,
	reportSlice,
	shareSlice
} from '@content-technology-partners-ltd/shared-features';
import {
	authReducer,
	configReducer,
	mediaApi,
	paymentApi,
	profileApi,
	publicApi,
	purchaseApi,
	authStorage
} from '@content-technology-partners-ltd/shared-data-access';
import { setupListeners } from '@reduxjs/toolkit/query';
import { popupParentMiddleware } from '@content-technology-partners-ltd/popup';
import { createReduxStorage } from '@content-technology-partners-ltd/shared-util';

const rootReducer = combineReducers({
	app: appReducer,
	authOld: authOldReducer,
	auth: authReducer,
	config: configReducer,
	me: meReducer,
	content: contentReducer,
	notification: notificationReducer,
	player: playerReducer,
	payment: paymentReducer,
	endpoints: endpointsReducer,
	signup: signupSlice.reducer,
	topUp: topUpSlice.reducer,
	purchase: purchaseSlice.reducer,
	report: reportSlice.reducer,
	share: shareSlice.reducer,
	[mediaApi.reducerPath]: mediaApi.reducer,
	[paymentApi.reducerPath]: paymentApi.reducer,
	[publicApi.reducerPath]: publicApi.reducer,
	[purchaseApi.reducerPath]: purchaseApi.reducer,
	[profileApi.reducerPath]: profileApi.reducer
});

export default function setupStore() {
	const reduxStorageEntries = [...purchaseStorage, ...authStorage];

	const reduxStorage = createReduxStorage(reduxStorageEntries);

	const store = configureStore({
		reducer: rootReducer,
		devTools: isDevelopment,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware()
				.prepend(createListenerMiddleware().middleware)
				.concat(
					mediaApi.middleware,
					paymentApi.middleware,
					signupApiErrorMiddleware,
					publicApi.middleware,
					purchaseApi.middleware,
					profileApi.middleware,
					popupParentMiddleware([
						'config',
						'signup',
						'mediaApi',
						'topUp',
						'paymentApi',
						'auth',
						'purchase',
						'app',
						'report',
						'share'
					]), // todo 6229: double check if we need all of it
					reduxStorage.middleware
				)
	});

	if (isDevelopment && module.hot) {
		module.hot.accept('./app/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./auth/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./me/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./content/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./notification/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./player/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./payment/reducer', () => store.replaceReducer(rootReducer));
		module.hot.accept('./endpoints/reducer', () => store.replaceReducer(rootReducer));
	}

	setupListeners(store.dispatch);
	store.dispatch(reduxStorage.init());

	return store;
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

// generic thunk action return type (thunk action is any action that does not return a simple object but a function)

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// generic dispatch type for thunk actions
export type AppThunkDispatch = ThunkDispatch<RootState, null, Action<string>>;
